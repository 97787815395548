import { Timestamp } from 'firebase/firestore';
import { FieldTypeId } from '../../types/System.types';

const defineFieldTypeFromDataType = (value: any): FieldTypeId => {
    if (value === null || value === undefined) return 'text';
    
    if (Array.isArray(value)) {
        return value.some(item => typeof item === 'object' && item !== null) 
            ? 'table' 
            : 'list';
    }
    
    if (value instanceof Date || value instanceof Timestamp) {
        const dateValue = value instanceof Date ? value : value.toDate();
        return (dateValue.getHours() || dateValue.getMinutes() || dateValue.getSeconds())
            ? 'dateTime'
            : 'date';
    }
    
    if (typeof value === 'object') {
        return ('start' in value && 'end' in value) ? 'dateTimeRange' : 'json';
    }

    const typeMap: Record<string, FieldTypeId> = {
        'string': 'text',
        'number': 'number',
        'boolean': 'yesNo'
    };
    return typeMap[typeof value] || 'text';
};

export default defineFieldTypeFromDataType; 