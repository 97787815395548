import { FC, useContext, useEffect } from 'react';
import { Skeleton, Typography, Alert, Switch, Divider, Button, Space, Input } from 'antd';
import FlexCard from '../atoms/FlexCard';
import LayoutUI from './LayoutUI';
import UnmappedFieldsUI from './UnmappedFieldsUI';
import EntityRecordSelector from '../artifacts/EntityRecordSelector';
import FlexBox from '../atoms/FlexBox';
import FlexCol from '../atoms/FlexCol';
import { EntityContext } from '../../providers/EntityProvider';
import { PlusOutlined } from '@ant-design/icons';

const { Text } = Typography;

const EntityUI: FC = () => {
  const {
    selectedEntity: entity,
    selectedEntityRecord: entityRecord,
    loading,
    error,
    readOnly,
    setReadOnly,
    insideEntityContext,
    createNewRecord,
    debouncedSetRecord,
  } = useContext(EntityContext);

  if (loading.entity) {
    return <Skeleton active />;
  }

  if (error.entity) {
    return (
      <Alert
        message={error.entity?.message}
        type="error"
        style={{
          width: '100%',
        }}
      />
    );
  }

  if (!entity || !insideEntityContext) {
    return (
      <Alert
        message="Error: Missing entity data."
        type="error"
        style={{
          width: '100%',
        }}
      />
    );
  }

  const handleAddNewRecord = async () => {
    try {
      await createNewRecord();
      setReadOnly(false);
    } catch (error) {
      console.error('Error creating new record:', error);
    }
  };

  return (
    <FlexCard
      justifyStart
      alignStart
      stretchCard
      growCard
      style={{
        marginBottom: 5,
      }}
      styles={{
        body: {
          width: '100%',
          paddingTop: 5,
        }
      }}
    >
      <FlexBox>
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Text type="secondary">
            <small>
              {entity?.description?.shortLabel || entity?.description?.shortDescription || entity?.name}
            </small>
          </Text>
        </Space>
      </FlexBox>
      <FlexBox justifyStart gap={15} wrap>
        <EntityRecordSelector />
        <FlexBox noGrow gap={5}>
          <Text type={readOnly ? 'secondary' : 'warning'} style={{ whiteSpace: 'nowrap' }}>
            <small>
              {readOnly ? 'Edit ' : 'Editing '}
            </small>
          </Text>
          <Switch
            size='small'
            checked={!readOnly}
            onChange={() => setReadOnly(!readOnly)}
          />
        </FlexBox>
        <Button
          type="link"
          size="small"
          onClick={handleAddNewRecord}
          loading={loading.records}
          icon={<PlusOutlined />}
        >
          Add Record
        </Button>
      </FlexBox>
      <Divider
        orientation='left'
        orientationMargin={0}
        dashed
        style={{ marginTop: 10, marginBottom: 10 }}
      />
      {entityRecord && (
        <FlexCol gap={0}>
          <Typography.Text>
            Record Name
          </Typography.Text>
          <Input
            placeholder="Record Name"
            value={entityRecord.name}
            onChange={(e) => debouncedSetRecord({ name: e.target.value })}
            disabled={readOnly}
            style={{ marginBottom: 10 }}
          />
        </FlexCol>
      )}
      {entityRecord && entity?.defaultUILayout && (
        <LayoutUI
          uiElement={entity.defaultUILayout.structure}
          itemData={entityRecord}
        />
      )}
      {entityRecord && (
        <UnmappedFieldsUI
          uiElement={entity?.defaultUILayout?.structure}
          itemData={entityRecord}
        />
      )}
      {!entityRecord && (
        <Text>
          No entity record selected.
        </Text>
      )}
    </FlexCard>
  );
};

export default EntityUI;