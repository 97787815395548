import { memo, useCallback, useMemo, Suspense, lazy } from 'react';
import {
    Input,
    InputNumber,
    Typography,
    Spin,
    DatePicker,
    Switch,
} from 'antd';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';

import { 
    Field, 
    UIField, 
    fieldType, 
    QosValues 
} from '../../types/System.types';
import type { ValidationStatus } from './FieldUI';
import TableUI from './tables/TableUI';
import defineFieldTypeFromDataType from './defineFieldTypeFromDataType';

const TimePicker = lazy(() => import('antd/lib/time-picker'));
const Select = lazy(() => import('antd/lib/select'));
const Upload = lazy(() => import('antd/lib/upload'));
const Image = lazy(() => import('antd/lib/image'));
const ListUI = lazy(() => import('./tables/ListUI'));
const QosComponent = lazy(() => import('../orgs/ClwQos').then(module => ({ default: module.QosComponent })));

type TimeRange = {
    start: Timestamp;
    end: Timestamp;
};

interface ControlUIProps {
    field: Field | undefined;
    uiField: UIField;
    onChange: (value: any) => void;
    id?: string;
    readOnly?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    status?: ValidationStatus;
    value: any;
    [key: string]: any;
}

const ControlUI = memo(({
    id,
    field,
    uiField,
    onChange,
    readOnly = false,
    onFocus,
    onBlur,
    status,
    value,
    ...props
}: ControlUIProps) => {
    const effectiveType = useMemo(() => {
        const definedType = fieldType[uiField.fieldTypeId]?.fieldDataTypeId;
        
        if (uiField.fieldTypeId === 'undefined' || !definedType) {
            return defineFieldTypeFromDataType(field?.value ?? value);
        }

        return definedType;
    }, [uiField.fieldTypeId, field?.value, value]);

    const commonProps = useMemo(() => ({
        id: id || uiField.fieldId,
        value,
        onFocus,
        onBlur,
        status,
        variant: 'filled' as const,
        disabled: readOnly,
        ...uiField.props,
        ...props
    }), [uiField.fieldId, value, onFocus, onBlur, status, readOnly, uiField.props, props]);

    const renderControl = useCallback(() => {
        switch(effectiveType) {
            case 'shortText':
            case 'text':
            case 'email':
            case 'phone':
            case 'password':
                return <Input {...commonProps} onChange={(e) => onChange(e.target.value)} />;

            case 'longText':
            case 'richText':
            case 'json':
            case 'xml':
            case 'html':
            case 'ipAddress':
            case 'address':
                return <Input.TextArea {...commonProps} onChange={(e) => onChange(e.target.value)} autoSize />;

            case 'url':
                return (
                    <Input.Search
                        {...commonProps}
                        type='url'
                        enterButton="Open"
                        onChange={(e) => onChange(e.target.value)}
                        onSearch={(value) => {
                            if (value) {
                                window.open(fieldType[uiField.fieldTypeId]?.parser?.(value), '_blank', 'noopener,noreferrer');
                            }
                        }}
                    />
                );

            case 'quantity':
            case 'number':
            case 'currency':
            case 'percentage':
                const thisFieldType = fieldType[uiField.fieldTypeId];
                const formatter = thisFieldType?.formatter;
                const parser = thisFieldType?.parser;
                const prefix = thisFieldType?.prefix;
                const suffix = thisFieldType?.suffix;
                return (
                    <InputNumber
                        {...commonProps}
                        formatter={formatter}
                        parser={parser}
                        prefix={prefix && (
                            <Typography.Text type="secondary">
                                {prefix}
                            </Typography.Text>
                        )}
                        suffix={suffix && (
                            <Typography.Text type="secondary">
                                {suffix}
                            </Typography.Text>
                        )}
                        onChange={(value) => onChange(value)}
                    />
                );

            case 'yesNo':
                return (
                    <Switch
                        {...commonProps}
                        checked={value as boolean}
                        onChange={(checked) => onChange(checked)}
                    />
                );

            case 'singleSelect':
                return (
                    <Suspense fallback={<Spin size="small" />}>
                        <Select {...commonProps} />
                    </Suspense>
                );

            case 'multiSelect':
                return (
                    <Suspense fallback={<Spin size="small" />}>
                        <Select mode="multiple" {...commonProps} />
                    </Suspense>
                );

            case 'date':
                return (
                    <DatePicker 
                        {...commonProps}
                        value={value ? dayjs(value.toDate()) : null}
                        onChange={(date) => onChange(date ? Timestamp.fromDate(date.toDate()) : null)}
                    />
                );

            case 'dateTime':
                return (
                    <DatePicker 
                        {...commonProps}
                        showTime
                        value={value ? dayjs(value.toDate()) : null}
                        onChange={(date) => onChange(date ? Timestamp.fromDate(date.toDate()) : null)}
                    />
                );

            case 'time':
                return (
                    <Suspense fallback={<Spin size="small" />}>
                        <TimePicker 
                            {...commonProps}
                            value={value ? dayjs(value.toDate()) : null}
                            onChange={(time) => onChange(time ? Timestamp.fromDate(time.toDate()) : null)}
                        />
                    </Suspense>
                );

            case 'image':
                return (
                    <Suspense fallback={<Spin size="small" />}>
                        <Image {...commonProps} />
                    </Suspense>
                );

            case 'file':
                return (
                    <Suspense fallback={<Spin size="small" />}>
                        <Upload {...commonProps} />
                    </Suspense>
                );

            case 'timeRange':
            case 'dateRange':
            case 'dateTimeRange':
                const range = value as TimeRange;
                return (
                    <DatePicker.RangePicker
                        {...commonProps}
                        allowEmpty={true}
                        showTime={effectiveType === 'timeRange' || effectiveType === 'dateTimeRange'}
                        value={range ? [dayjs(range.start.toDate()), dayjs(range.end.toDate())] : null}
                        onChange={(dates) => {
                            if (dates && dates[0] && dates[1]) {
                                onChange({
                                    start: Timestamp.fromDate(dates[0].toDate()),
                                    end: Timestamp.fromDate(dates[1].toDate())
                                });
                            } else {
                                onChange(null);
                            }
                        }}
                    />
                );

            case 'table':
                return (
                    <TableUI
                        id={id || uiField.fieldId}
                        dataSource={value as any[]}
                        columns={uiField.columns || []}
                        onChange={onChange}
                        readOnly={readOnly}
                        {...uiField.props}
                    />
                );

            case 'list':
                return (
                    <Suspense fallback={<Spin size="small" />}>
                        <ListUI
                            id={id || uiField.fieldId}
                            dataSource={value as any[]}
                            onChange={onChange}
                            readOnly={readOnly}
                            {...uiField.props}
                        />
                    </Suspense>
                );

            case 'qos':
                return (
                    <Suspense fallback={<Spin size="small" />}>
                        <QosComponent
                            qos={value as QosValues}
                            onChange={onChange}
                            {...commonProps}
                        />
                    </Suspense>
                );

            default:
                console.error(`Unknown field type: ${effectiveType}`);
                return (
                    <Typography.Text type="danger">
                        Unable to render field type: {effectiveType}
                    </Typography.Text>
                );
        }
    }, [effectiveType, commonProps, value, onChange, uiField.fieldId]);

    return renderControl();
});

ControlUI.displayName = 'ControlUI';

export default ControlUI; 