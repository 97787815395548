import { FC, useMemo, lazy, Suspense } from 'react';
import {
    Typography,
    Grid,
    Spin,
} from 'antd';
import type { TableProps } from 'antd/es/table';

import {
    TableColumnType,
    TableAggregationType,
} from '../../../types/System.types';
import { calculateColumnAggregations, formatAggregation } from './columnSumaryStats';
import { calculateRow } from './formulaCalculator';
import { useFormulaParser } from '../../../use/useFormulaParser';

const MobileTableView = lazy(() => import('./atoms/MobileTableView'));
const DesktopTableView = lazy(() => import('./atoms/DesktopTableView'));

const { useBreakpoint } = Grid;

interface TableUIProps {
    dataSource?: any[];
    columns: TableColumnType[];
    onChange?: (updatedData: any[]) => void;
    readOnly?: boolean;
    id?: string;
    showColumnAggregations?: boolean;
    columnAggregations?: {
        [columnKey: string]: TableAggregationType | ColumnAggregationConfig;
    };
    pagination?: TableProps<any>['pagination'];
    scroll?: TableProps<any>['scroll'];
    style?: TableProps<any>['style'];
    className?: TableProps<any>['className'];
}

interface ColumnAggregationConfig {
    aggregationType?: TableAggregationType;
    customCalculation?: (values: readonly any[]) => number;
}

const TableUI: FC<TableUIProps> = ({
    dataSource = [],
    columns,
    onChange,
    readOnly,
    id,
    showColumnAggregations = true,
    columnAggregations = {},
    ...props
}) => {
    const screens = useBreakpoint();
    const { evaluateFormula } = useFormulaParser();

    const handleCellChange = (rowIndex: number, columnKey: string, value: any) => {
        if (onChange && !readOnly) {
            const updatedData = [...dataSource];
            const currentRow = { ...updatedData[rowIndex], [columnKey]: value };

            updatedData[rowIndex] = calculateRow(currentRow, columns, evaluateFormula);
            onChange(updatedData);
        }
    };

    const handleAddRow = () => {
        if (onChange && !readOnly) {
            const newRow = columns.reduce<Record<string, any>>((acc, col) => ({
                ...acc,
                [col.dataIndex]: null
            }), {});
            onChange([...dataSource, newRow]);
        }
    };

    const handleRemoveRow = (index: number) => {
        if (onChange && !readOnly) {
            const updatedData = [...dataSource];
            updatedData.splice(index, 1);
            onChange(updatedData);
        }
    };

    const columnAggregationResults = useMemo(() => 
        calculateColumnAggregations(dataSource, columns, showColumnAggregations),
        [dataSource, columns, showColumnAggregations]
    );

    const tableProps = {
        dataSource,
        columns,
        onCellChange: handleCellChange,
        readOnly,
        id,
        onAddRow: handleAddRow,
        onRemoveRow: handleRemoveRow,
        showColumnAggregations,
        columnAggregationResults,
        formatAggregation,
        ...props
    };

    return (
        <Suspense fallback={<Spin size="large" />}>
            {!screens.md ? (
                <MobileTableView {...tableProps} />
            ) : (
                <DesktopTableView {...tableProps} />
            )}
        </Suspense>
    );
};

export default TableUI;
