import { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { ValidationStatus } from '../components/rendering/FieldUI';

interface FieldStateOptions<T> {
    initialValue: T | null;
    handleDBSave: (value: T) => void;
    validation?: (value: T) => boolean;
    tempId: string;
}

/**
 * @description
 * Manages the state of a field in a UI context.
 */
export const useFieldUIState = <T>({
    initialValue,
    handleDBSave,
    validation,
    tempId,
}: FieldStateOptions<T>) => {
    const [value, setValueInternal] = useState<T | null>(initialValue);
    const [validationStatus, setValidationStatus] = useState<ValidationStatus>(undefined);

    const [isSaving, setIsSaving] = useState(false);

    const debouncedSave = useCallback(
        debounce((newValue: T) => {
            try {
                handleDBSave(newValue);
                setTimeout(() => setIsSaving(false), 350);
            } catch (error) {
                console.error('Save failed:', error);
                setValidationStatus('error');
                setIsSaving(false);
            }
        }, 350),
        [handleDBSave]
    );

    const setValue = useCallback((newValue: T) => {
        setValueInternal(newValue);
        setIsSaving(true);

        const isValid = validation ? validation(newValue) : true;
        setValidationStatus(isValid ? undefined : 'error');

        if (isValid) {
            debouncedSave(newValue);
        } else {
            setIsSaving(false);
            debouncedSave.cancel();
        }
    }, [validation, debouncedSave]);

    useEffect(() => {
        setValueInternal(initialValue);
        setIsSaving(false);
    }, [initialValue]);

    useEffect(() => {
        return () => {
            debouncedSave.cancel();
        };
    }, [debouncedSave]);

    return {
        value,
        setValue,
        validationStatus,
        setValidationStatus,
        isSaving,
    };
}; 