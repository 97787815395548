/**
 * EntityContext provides entity-related data and actions to components.
 * It manages entities, entity records, selection, and CRUD operations.
 */
import React, { createContext, useState, useCallback, useMemo, FC } from 'react';
import { useBoundCollection } from '../use/data/useBoundCollection';
import { useBoundDoc } from '../use/data/useBoundDoc';
import { useAdd } from '../use/data/useAdd';
import { Entity, EntityRecord, DocId } from '../types/System.types';
import { serverTimestamp } from 'firebase/firestore';

interface EntityContextValue {
  entities: Entity[];
  selectedEntity: Entity | null;
  selectEntity: (entityId: string | undefined) => void;
  entityRecords: EntityRecord[];
  selectedEntityRecord: EntityRecord | null;
  selectEntityRecord: (recordId: string | undefined) => void;
  setRecord: (updates: Partial<EntityRecord>) => void;
  debouncedSetRecord: (updates: Partial<EntityRecord>) => void;
  loading: {
    entities: boolean;
    entity: boolean;
    records: boolean;
    record: boolean;
  };
  error: {
    entities: Error | null;
    entity: Error | null;
    records: Error | null;
    record: Error | null;
  };
  readOnly: boolean;
  setReadOnly: (value: boolean) => void;
  insideEntityContext: boolean;
  createNewRecord: () => Promise<DocId>;
}

export const EntityContext = createContext<EntityContextValue>({} as EntityContextValue);

interface EntityProviderProps {
  initialEntityId?: DocId;
  initialRecordId?: DocId;
  children: React.ReactNode;
}

export const EntityProvider: FC<EntityProviderProps> = ({ children, initialEntityId, initialRecordId }) => {
  const [selectedEntityId, setSelectedEntityId] = useState<DocId | undefined>(initialEntityId);
  const [selectedEntityRecordId, setSelectedEntityRecordId] = useState<DocId | undefined>(initialRecordId);
  const [readOnly, setReadOnly] = useState(true);

  const { 
    data: entities = [], 
    loading: entitiesLoading, 
    error: entitiesError 
  } = useBoundCollection<Entity>({
    path: 'entities',
    initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
    initialLimit: 50,
  });

  const { 
    data: selectedEntity, 
    loading: entityLoading, 
    error: entityError 
  } = useBoundDoc<Entity>({
    path: 'entities',
    docId: selectedEntityId,
    enabled: !!selectedEntityId,
  });

  const { 
    data: entityRecords = [], 
    loading: recordsLoading, 
    error: recordsError 
  } = useBoundCollection<EntityRecord>({
    path: selectedEntityId ? `entities/${selectedEntityId}/records` : '',
    enabled: !!selectedEntityId,
  });

  const { 
    data: selectedEntityRecord, 
    loading: recordLoading, 
    error: recordError,
    updateData: setRecord,
    debouncedSet: debouncedSetRecord
  } = useBoundDoc<EntityRecord>({
    path: selectedEntityId ? `entities/${selectedEntityId}/records` : '',
    docId: selectedEntityRecordId,
    enabled: !!selectedEntityId && !!selectedEntityRecordId,
  });

  const { add } = useAdd();

  const selectEntity = useCallback((entityId: string | undefined) => {
    setSelectedEntityId(entityId);
    setSelectedEntityRecordId(undefined);
  }, []);

  const selectEntityRecord = useCallback((recordId: string | undefined) => {
    setSelectedEntityRecordId(recordId);
  }, []);

  const createNewRecord = useCallback(async () => {
    if (!selectedEntityId) throw new Error('No entity selected');
    const newRecord = {
      name: 'New Record',
      fields: {},  // Initialize empty fields object
      meta: {
        created: serverTimestamp(),
        modified: serverTimestamp(),
        status: 'active'
      }
    };
    const newRecordId = await add(`entities/${selectedEntityId}/records`, newRecord);
    selectEntityRecord(newRecordId);
    return newRecordId;
  }, [selectedEntityId, selectedEntity, selectedEntityRecord, add, selectEntityRecord]);

  const loading = { 
    entities: entitiesLoading, 
    entity: entityLoading, 
    records: recordsLoading, 
    record: recordLoading 
  };
  const error = { 
    entities: entitiesError, 
    entity: entityError, 
    records: recordsError, 
    record: recordError 
  };
  const contextValue = useMemo(
    () => ({
      entities,
      selectedEntity: selectedEntity || null,
      selectEntity,
      entityRecords,
      selectedEntityRecord: selectedEntityRecord || null,
      selectEntityRecord,
      setRecord,
      debouncedSetRecord,
      loading,
      error,
      readOnly,
      setReadOnly,
      insideEntityContext: true,
      createNewRecord,
    }),
    [
      entities,
      selectedEntity,
      selectEntity,
      entityRecords,
      selectedEntityRecord,
      selectEntityRecord,
      debouncedSetRecord,
      loading,
      error,
      readOnly,
      setReadOnly,
      createNewRecord,
    ]
  );

  return <EntityContext.Provider value={contextValue}>{children}</EntityContext.Provider>;
};