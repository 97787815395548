import React, { FC } from 'react';
import FlexBox, { FlexBoxProps } from './FlexBox';

export interface FlexColProps extends Omit<FlexBoxProps, 'column'> {
  gap?: number;
}

const FlexCol: FC<FlexColProps> = ({ 
  children, 
  alignStart = true,
  justifyStart = true,
  stretch = true,
  gap = 10,
  ...props 
}) => {
  return (
    <FlexBox
      column
      alignStart={alignStart}
      justifyStart={justifyStart}
      stretch={stretch}
      gap={gap}
      {...props}
    >
      {children}
    </FlexBox>
  );
};

export default FlexCol;