import { 
    TableColumnType, 
    TableAggregationType, 
    SystemFieldTypeId 
} from '../../../types/System.types';
import { fieldDataType } from '../../../types/System.FieldTypes.types';
import { Typography } from 'antd';
import type { ReactNode } from 'react';
import React from 'react';

const { Text } = Typography;

export const calculateColumnAggregations = (
    dataSource: any[],
    columns: TableColumnType[],
    showColumnAggregations: boolean
): Record<string, number> | null => {
    if (!showColumnAggregations) return null;

    return columns.reduce((acc, col) => {
        if (!col.aggregationType) {
            return acc;
        }

        const columnKey = col.dataIndex;
        const values = dataSource
            .map(row => row[columnKey])
            .filter(val => val !== null && val !== undefined)
            .map(val => Number(val))
            .filter(val => !isNaN(val));

        if (values.length === 0) {
            return acc;
        }

        let aggregationResult: number | null = null;
        switch (col.aggregationType) {
            case 'sum':
                aggregationResult = values.reduce((a, b) => a + b, 0);
                break;
            case 'average':
                aggregationResult = values.reduce((a, b) => a + b, 0) / values.length;
                break;
            case 'min':
                aggregationResult = Math.min(...values);
                break;
            case 'max':
                aggregationResult = Math.max(...values);
                break;
            case 'count':
                aggregationResult = values.length;
                break;
            default:
                return acc;
        }

        if (aggregationResult !== null) {
            acc[columnKey] = aggregationResult;
        }
        return acc;
    }, {} as Record<string, number>);
};

export const formatAggregation = (
    value: number, 
    fieldTypeId: SystemFieldTypeId, 
    aggregationType?: TableAggregationType
): ReactNode => {
    const baseType = fieldDataType[fieldTypeId]?.fieldDataTypeId;

    let formattedValue: ReactNode;
    switch (baseType) {
        case 'currency':
            formattedValue = React.createElement(
                Text,
                null,
                React.createElement(Text, { type: "secondary" }, "$"),
                value.toFixed(2)
            );
            break;
        case 'percentage':
            formattedValue = React.createElement(
                Text,
                null,
                value.toFixed(2),
                React.createElement(Text, { type: "secondary" }, "%")
            );
            break;
        default:
            formattedValue = React.createElement(Text, null, value.toFixed(2));
    }

    if (aggregationType && aggregationType !== 'sum') {
        return React.createElement(
            Text,
            null,
            formattedValue,
            React.createElement(
                Text,
                { type: "secondary", style: { marginLeft: 4 } },
                React.createElement('small', null, `(${aggregationType})`)
            )
        );
    }

    return formattedValue;
}; 