// WARNING: If you add imports here these will also impact sub repositories (cloudRun, functions, etc), you might need to add imports there as well.
import { FieldType, RecordStatus } from './System.types';
import { fieldDataType } from './System.FieldTypes.types';

export type ConfigFieldTypeId =
  | 'attContactDbId'
  | 'attContactId'
  | 'autoSplitCalc'
  | 'bidDate'
  | 'bidName'
  | 'billingClosed'
  | 'bsId'
  | 'createDate'
  | 'createTerritoryId'
  | 'createUserId'
  | 'csrUserId'
  | 'dataVersion'
  | 'doNotShipBefore'
  | 'entityStatus'
  | 'expectedDeliveryDate'
  | 'expirationDate'
  | 'fileDat'
  | 'formLocked'
  | 'formName'
  | 'formNotes'
  | 'formNumber'
  | 'quoteStatus'
  | 'poStatus'
  | 'likelihood'
  | 'lineItems'
  | 'lockedBy'
  | 'location'
  | 'locationState'
  | 'lostReasonCode'
  | 'lostTo'
  | 'nextLineUid'
  | 'parentSubId'
  | 'parentType'
  | 'projectId'
  | 'quoterUserId'
  | 'specRegistration'
  | 'stamp'
  | 'updateDate'
  | 'updateUserId'
  | 'verticalMarket'
  | 'qos'
  | 'projectName'
  | 'bomRevision'
  | 'revisionDate'
  | 'totalItems'
  | 'totalCost'
  | 'totalWeight'
  | 'relatedDocumentType'
  | 'relatedDocumentNumber'
  | 'changeOrderDate'
  | 'requestedImplementationDate'
  | 'changeDescription'
  | 'changeJustification'
  | 'costImpact'
  | 'scheduleImpact'
  | 'salesRepId'
  | 'salesRepName'
  | 'statementPeriodStart'
  | 'statementPeriodEnd'
  | 'commissionItems'
  | 'totalCommission'
  | 'adjustments'
  | 'netCommission'
  | 'customerName'
  | 'projectType'
  | 'lightingRequirements'
  | 'estimatedBudget'
  | 'targetCompletionDate'
  | 'holdDate'
  | 'expectedReleaseDate'
  | 'holdReason'
  | 'releaseConditions'
  | 'affectedItems'
  | 'estimatedCostImpact'
  | 'estimatedScheduleImpact'
  | 'invoiceDate'
  | 'dueDate'
  | 'billName'
  | 'billAddress'
  | 'shipName'
  | 'shipAddress'
  | 'totalPrice'
  | 'totalEarnings'
  | 'relatedOrder'
  | 'project'
  | 'paymentId'
  | 'buySell'
  | 'paymentDate'
  | 'paymentAmount'
  | 'paymentMethod'
  | 'checkNumber'
  | 'relatedInvoice'
  | 'dateReceived'
  | 'invoiceStartDate'
  | 'invoiceEndDate'
  | 'posted'
  | 'postedDate'
  | 'basePrice'
  | 'sellPrice'
  | 'baseCommissionRate'
  | 'baseCommissionAmount'
  | 'overageCommissionRate'
  | 'overageAmount'
  | 'overageCommissionAmount'
  | 'totalCommission'
  | 'effectiveCommissionRate'
  | 'lineNote'
  | 'lineType'
  | 'shipmentId'
  | 'shipmentDate'
  | 'weight'
  | 'dimensions'
  | 'shippingMethod'
  | 'trackingNumber'
  | 'submittalDate'
  | 'description'
  | 'submittedBy'
  | 'reviewedBy'
  | 'reviewDate'
  | 'extendedSellPrice'
  | 'lineItemsSummary'
  | 'lineItemsCommission'
  | 'totalSaleAmount'
  | 'paymentTerms'
  | 'totalCommissionAmount'
  | 'effectiveCommissionRate'
  | 'billOfMaterials';

export const configFieldType: Record<ConfigFieldTypeId, FieldType> = {
  attContactDbId: {
    fieldTypeId: 'attContactDbId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'ATT Contact DB ID',
      longDescription: 'ATT Contact Database ID',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  attContactId: {
    fieldTypeId: 'attContactId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'ATT Contact ID',
      longDescription: 'ATT Contact ID',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  autoSplitCalc: {
    fieldTypeId: 'autoSplitCalc',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Auto Split Calc',
      longDescription: 'Indicates if auto split calculation is enabled',
    },
    status: RecordStatus.Active,
    defaultValue: false,
    validation: fieldDataType.yesNo.validation,
    errorMessage: fieldDataType.yesNo.errorMessage,
  },
  bidDate: {
    fieldTypeId: 'bidDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Bid Date',
      longDescription: 'Date of the bid',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  bidName: {
    fieldTypeId: 'bidName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Bid Name',
      longDescription: 'Name of the bid',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  billingClosed: {
    fieldTypeId: 'billingClosed',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Billing Closed',
      longDescription: 'Indicates if billing is closed for this form',
    },
    status: RecordStatus.Active,
    defaultValue: false,
    validation: fieldDataType.yesNo.validation,
    errorMessage: fieldDataType.yesNo.errorMessage,
  },
  bsId: {
    fieldTypeId: 'bsId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'BS ID',
      longDescription: 'Business System ID',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  createDate: {
    fieldTypeId: 'createDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Create Date',
      longDescription: 'Date when the form was created',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  createTerritoryId: {
    fieldTypeId: 'createTerritoryId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Create Territory ID',
      longDescription: 'ID of the territory where the form was created',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  createUserId: {
    fieldTypeId: 'createUserId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Create User Initials',
      longDescription: 'ID of the user who created the form',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  csrUserId: {
    fieldTypeId: 'csrUserId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'CSR User Initials',
      longDescription: 'Customer Service Representative User Initials',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  dataVersion: {
    fieldTypeId: 'dataVersion',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Data Version',
      longDescription: 'Version of the form data',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  doNotShipBefore: {
    fieldTypeId: 'doNotShipBefore',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Do Not Ship Before',
      longDescription: 'Earliest date for shipping',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  entityStatus: {
    fieldTypeId: 'entityStatus',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Status',
      longDescription: 'Status of the entity',
    },
    status: RecordStatus.Active,
    defaultValue: 'Active',
    validation: (value: any) => ['Active', 'Inactive'].includes(value),
    errorMessage: 'Must be either Active or Inactive',
  },
  expectedDeliveryDate: {
    fieldTypeId: 'expectedDeliveryDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Expected Delivery Date',
      longDescription: 'Expected date of delivery for the form',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  expirationDate: {
    fieldTypeId: 'expirationDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Expiration Date',
      longDescription: 'Date when the form expires',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  fileDat: {
    fieldTypeId: 'fileDat',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'File DAT',
      longDescription: 'File DAT information',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  formLocked: {
    fieldTypeId: 'formLocked',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Form Locked',
      longDescription: 'Indicates whether the form is locked for editing',
    },
    status: RecordStatus.Active,
    defaultValue: false,
    validation: fieldDataType.yesNo.validation,
    errorMessage: fieldDataType.yesNo.errorMessage,
  },
  formName: {
    fieldTypeId: 'formName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Form Name',
      longDescription: 'Name of the form',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  formNotes: {
    fieldTypeId: 'formNotes',
    fieldDataTypeId: 'longText',
    description: {
      shortLabel: 'Notes for Customer',
      longDescription: 'Notes that will be shared with the customer',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.longText.validation,
    errorMessage: fieldDataType.longText.errorMessage,
  },
  formNumber: {
    fieldTypeId: 'formNumber',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Form Number',
      longDescription: 'Unique identifier for the form',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  quoteStatus: {
    fieldTypeId: 'quoteStatus',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Quote Status',
      longDescription: 'Current status of the quote: ' +
        '0 (New), ' +
        '1 (Prebid/Design), ' +
        '2 (Quoted), ' +
        '3 (Order pending), ' +
        '5 (Abandoned), ' +
        '6 (Lost), ' +
        '7 (Submitted)',
    },
    status: RecordStatus.Active,
    defaultValue: '0 (New)',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  poStatus: {
    fieldTypeId: 'poStatus',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'PO Status',
      longDescription: 'Current status of the purchase order: ' +
        '-1 (Hold For Release), ' +
        '0 (New), ' +
        '1 (Acknowledge), ' +
        '2 (Scheduled), ' +
        '3 (Shipped), ' +
        '5 (Invoiced), ' +
        '6 (Closed), ' +
        '7 (Order Entry Error), ' +
        '8 (Will Call), ' +
        '9 (RFC), ' +
        '10 (Partial HFR), ' +
        '11 (Clarification), ' +
        '12 (Cancel), ' +
        '13 (Promo), ' +
        '14 (Post Sales), ' +
        '15 (Will Call Complete)',
    },
    status: RecordStatus.Active,
    defaultValue: '0 (New)',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  likelihood: {
    fieldTypeId: 'likelihood',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Likelihood',
      longDescription: 'Likelihood of winning the bid',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  lineItems: {
    fieldTypeId: 'lineItems',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'Line Items',
      longDescription: 'Table of line items in the Bill of Materials',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: fieldDataType.table.validation,
    errorMessage: fieldDataType.table.errorMessage,
  },
  lockedBy: {
    fieldTypeId: 'lockedBy',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Locked By',
      longDescription: 'User who has locked the form',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  location: {
    fieldTypeId: 'location',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Location',
      longDescription: 'Location for the form',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  locationState: {
    fieldTypeId: 'locationState',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Location State',
      longDescription: 'State of the location',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  lostReasonCode: {
    fieldTypeId: 'lostReasonCode',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Lost Reason Code',
      longDescription: 'Code indicating the reason for losing the bid',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  lostTo: {
    fieldTypeId: 'lostTo',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Lost To',
      longDescription: 'Competitor to whom the bid was lost',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  nextLineUid: {
    fieldTypeId: 'nextLineUid',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Next Line UID',
      longDescription: 'Unique ID for the next line item',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  parentSubId: {
    fieldTypeId: 'parentSubId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Parent Sub ID',
      longDescription: 'Sub ID of the parent form',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  parentType: {
    fieldTypeId: 'parentType',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Parent Type',
      longDescription: 'Type of the parent form',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  projectId: {
    fieldTypeId: 'projectId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Historical Project ID',
      longDescription: 'Historical Project ID of the associated project',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  quoterUserId: {
    fieldTypeId: 'quoterUserId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Quoter User Initials',
      longDescription: 'ID of the user who created the quote',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  specRegistration: {
    fieldTypeId: 'specRegistration',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Spec Registration',
      longDescription: 'Specification Registration',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  stamp: {
    fieldTypeId: 'stamp',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Stamp',
      longDescription: 'Stamp information',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  updateDate: {
    fieldTypeId: 'updateDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Last Updated',
      longDescription: 'Date when the form was last updated',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  updateUserId: {
    fieldTypeId: 'updateUserId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Last Updated By',
      longDescription: 'ID of the user who last updated the form',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  verticalMarket: {
    fieldTypeId: 'verticalMarket',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Vertical Market',
      longDescription: 'Vertical market for the form',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  qos: {
    fieldTypeId: 'qos',
    fieldDataTypeId: 'qos',
    description: {
      shortLabel: 'Quality of Specification',
      longDescription: 'Quality of Specification',
    },
    status: RecordStatus.Active,
    validation: fieldDataType.qos.validation,
    errorMessage: fieldDataType.qos.errorMessage,
  },
  projectName: {
    fieldTypeId: 'projectName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Project Name',
      longDescription: 'Name of the project associated with the Bill of Materials',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  bomRevision: {
    fieldTypeId: 'bomRevision',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'BOM Revision',
      longDescription: 'Revision number or identifier for the Bill of Materials',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  revisionDate: {
    fieldTypeId: 'revisionDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Revision Date',
      longDescription: 'Date of the current revision for the Bill of Materials',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  totalItems: {
    fieldTypeId: 'totalItems',
    fieldDataTypeId: 'number',
    description: {
      shortLabel: 'Total Items',
      longDescription: 'Total number of items in the Bill of Materials',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  },
  totalCost: {
    fieldTypeId: 'totalCost',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Total Cost',
      longDescription: 'Total cost of all items in the Bill of Materials',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  totalWeight: {
    fieldTypeId: 'totalWeight',
    fieldDataTypeId: 'number',
    description: {
      shortLabel: 'Total Weight',
      longDescription: 'Total weight of all items in the Bill of Materials',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  },
  relatedDocumentType: {
    fieldTypeId: 'relatedDocumentType',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Related Document Type',
      longDescription: 'Type of the document related to this change order',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  relatedDocumentNumber: {
    fieldTypeId: 'relatedDocumentNumber',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Related Document Number',
      longDescription: 'Number of the document related to this change order',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  changeOrderDate: {
    fieldTypeId: 'changeOrderDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Change Order Date',
      longDescription: 'Date of the change order',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  requestedImplementationDate: {
    fieldTypeId: 'requestedImplementationDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Requested Implementation Date',
      longDescription: 'Date requested for implementing the change order',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  changeDescription: {
    fieldTypeId: 'changeDescription',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Change Description',
      longDescription: 'Detailed description of the proposed change',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  changeJustification: {
    fieldTypeId: 'changeJustification',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Change Justification',
      longDescription: 'Justification for the proposed change',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  costImpact: {
    fieldTypeId: 'costImpact',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Cost Impact',
      longDescription: 'Financial impact of the proposed change',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  scheduleImpact: {
    fieldTypeId: 'scheduleImpact',
    fieldDataTypeId: 'number',
    description: {
      shortLabel: 'Schedule Impact',
      longDescription: 'Impact on the project schedule in days',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  },
  salesRepId: {
    fieldTypeId: 'salesRepId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Sales Rep ID',
      longDescription: 'Unique identifier for the sales representative',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  salesRepName: {
    fieldTypeId: 'salesRepName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Sales Rep Name',
      longDescription: 'Full name of the sales representative',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  statementPeriodStart: {
    fieldTypeId: 'statementPeriodStart',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Statement Period Start',
      longDescription: 'Start date of the commission statement period',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  statementPeriodEnd: {
    fieldTypeId: 'statementPeriodEnd',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Statement Period End',
      longDescription: 'End date of the commission statement period',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  commissionItems: {
    fieldTypeId: 'commissionItems',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'Commission Items',
      longDescription: 'Table of commission items for the statement',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: fieldDataType.table.validation,
    errorMessage: fieldDataType.table.errorMessage,
  },
  totalCommission: {
    fieldTypeId: 'totalCommission',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Total Commission',
      longDescription: 'Total commission amount before adjustments',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  adjustments: {
    fieldTypeId: 'adjustments',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Adjustments',
      longDescription: 'Adjustments to the total commission amount',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  netCommission: {
    fieldTypeId: 'netCommission',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Net Commission',
      longDescription: 'Final commission amount after adjustments',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  customerName: {
    fieldTypeId: 'customerName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Customer Name',
      longDescription: 'Name of the customer for the specification',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: 'Customer name is required',
  },
  projectType: {
    fieldTypeId: 'projectType',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Project Type',
      longDescription: 'Type or category of the project',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  lightingRequirements: {
    fieldTypeId: 'lightingRequirements',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Lighting Requirements',
      longDescription: 'Detailed description of the lighting requirements for the project',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  estimatedBudget: {
    fieldTypeId: 'estimatedBudget',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Estimated Budget',
      longDescription: 'Estimated budget for the lighting project',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  targetCompletionDate: {
    fieldTypeId: 'targetCompletionDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Target Completion Date',
      longDescription: 'Target date for completing the lighting project',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  holdDate: {
    fieldTypeId: 'holdDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Hold Date',
      longDescription: 'Date when the hold was initiated',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  expectedReleaseDate: {
    fieldTypeId: 'expectedReleaseDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Expected Release Date',
      longDescription: 'Anticipated date when the hold will be lifted',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  holdReason: {
    fieldTypeId: 'holdReason',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Hold Reason',
      longDescription: 'Explanation for why the hold was initiated',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  releaseConditions: {
    fieldTypeId: 'releaseConditions',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Release Conditions',
      longDescription: 'Conditions that must be met for the hold to be released',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  affectedItems: {
    fieldTypeId: 'affectedItems',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'Affected Items',
      longDescription: 'List of items affected by the hold',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: fieldDataType.table.validation,
    errorMessage: fieldDataType.table.errorMessage,
  },
  estimatedCostImpact: {
    fieldTypeId: 'estimatedCostImpact',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Estimated Cost Impact',
      longDescription: 'Estimated financial impact of the hold',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  estimatedScheduleImpact: {
    fieldTypeId: 'estimatedScheduleImpact',
    fieldDataTypeId: 'number',
    description: {
      shortLabel: 'Estimated Schedule Impact',
      longDescription: 'Estimated impact on the schedule in days',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  },
  invoiceDate: {
    fieldTypeId: 'invoiceDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Invoice Date',
      longDescription: 'Date when the invoice was created',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  dueDate: {
    fieldTypeId: 'dueDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Due Date',
      longDescription: 'Date when the payment is due',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  billName: {
    fieldTypeId: 'billName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Billing Name',
      longDescription: 'Name of the billing entity',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  billAddress: {
    fieldTypeId: 'billAddress',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Billing Address',
      longDescription: 'Full billing address',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  shipName: {
    fieldTypeId: 'shipName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Shipping Name',
      longDescription: 'Name for the shipping address',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  shipAddress: {
    fieldTypeId: 'shipAddress',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Shipping Address',
      longDescription: 'Full shipping address',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  totalPrice: {
    fieldTypeId: 'totalPrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Total Price',
      longDescription: 'Total price of all items in the invoice',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  totalEarnings: {
    fieldTypeId: 'totalEarnings',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Total Earnings',
      longDescription: 'Total earnings from the invoice',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  relatedOrder: {
    fieldTypeId: 'relatedOrder',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Related Order',
      longDescription: 'Reference to the related order',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  project: {
    fieldTypeId: 'project',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Project',
      longDescription: 'Associated project for the invoice',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  paymentId: {
    fieldTypeId: 'paymentId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Payment ID',
      longDescription: 'Unique identifier for the payment',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  buySell: {
    fieldTypeId: 'buySell',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Buy/Sell',
      longDescription: 'Indicates whether this is a payment for buying or selling',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: (value: any) => typeof value === 'string' && ['Buy', 'Sell'].includes(value),
    errorMessage: 'Must be either Buy or Sell',
  },
  paymentDate: {
    fieldTypeId: 'paymentDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Payment Date',
      longDescription: 'Date when the payment was made',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  paymentAmount: {
    fieldTypeId: 'paymentAmount',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Payment Amount',
      longDescription: 'Amount of the payment',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  paymentMethod: {
    fieldTypeId: 'paymentMethod',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Payment Method',
      longDescription: 'Method used for the payment (e.g., check, wire transfer, credit card)',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  checkNumber: {
    fieldTypeId: 'checkNumber',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Check Number',
      longDescription: 'Check number if payment method is check',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  relatedInvoice: {
    fieldTypeId: 'relatedInvoice',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Related Invoice',
      longDescription: 'Reference to the related invoice',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  dateReceived: {
    fieldTypeId: 'dateReceived',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Date Received',
      longDescription: 'Date when the payment was received',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  invoiceStartDate: {
    fieldTypeId: 'invoiceStartDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Invoice Start Date',
      longDescription: 'Start date of the invoice period',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  invoiceEndDate: {
    fieldTypeId: 'invoiceEndDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Invoice End Date',
      longDescription: 'End date of the invoice period',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  posted: {
    fieldTypeId: 'posted',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Posted',
      longDescription: 'Indicates whether the payment has been posted',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.yesNo.validation,
    errorMessage: fieldDataType.yesNo.errorMessage,
  },
  postedDate: {
    fieldTypeId: 'postedDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Posted Date',
      longDescription: 'Date when the payment was posted',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  basePrice: {
    fieldTypeId: 'basePrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Base Price',
      longDescription: 'Base price of the item before any adjustments',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  sellPrice: {
    fieldTypeId: 'sellPrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Sell Price',
      longDescription: 'Final selling price of the item',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  baseCommissionRate: {
    fieldTypeId: 'baseCommissionRate',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Base Commission Rate',
      longDescription: 'Base commission rate as a percentage',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  baseCommissionAmount: {
    fieldTypeId: 'baseCommissionAmount',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Base Commission Amount',
      longDescription: 'Amount of base commission',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  overageCommissionRate: {
    fieldTypeId: 'overageCommissionRate',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Overage Commission Rate',
      longDescription: 'Overage commission rate as a percentage',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  overageAmount: {
    fieldTypeId: 'overageAmount',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Overage Amount',
      longDescription: 'Amount of overage',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  overageCommissionAmount: {
    fieldTypeId: 'overageCommissionAmount',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Overage Commission Amount',
      longDescription: 'Amount of commission from overage',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  effectiveCommissionRate: {
    fieldTypeId: 'effectiveCommissionRate',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Effective Commission Rate',
      longDescription: 'Blended commission rate taking into account both base and overage commission',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  lineNote: {
    fieldTypeId: 'lineNote',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Line Note',
      longDescription: 'Additional note for the line item',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  lineType: {
    fieldTypeId: 'lineType',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Line Type',
      longDescription: 'Type of the line item',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  shipmentId: {
    fieldTypeId: 'shipmentId',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Shipment ID',
      longDescription: 'Unique identifier for the shipment',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  shipmentDate: {
    fieldTypeId: 'shipmentDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Shipment Date',
      longDescription: 'Date when the shipment was sent',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  weight: {
    fieldTypeId: 'weight',
    fieldDataTypeId: 'number',
    description: {
      shortLabel: 'Weight',
      longDescription: 'Weight of the shipment item',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  },
  dimensions: {
    fieldTypeId: 'dimensions',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Dimensions',
      longDescription: 'Dimensions of the shipment item',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  shippingMethod: {
    fieldTypeId: 'shippingMethod',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Shipping Method',
      longDescription: 'Method used for shipping (e.g., Ground, Express, Air)',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  trackingNumber: {
    fieldTypeId: 'trackingNumber',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Tracking Number',
      longDescription: 'Tracking number for the shipment',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  submittalDate: {
    fieldTypeId: 'submittalDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Submittal Date',
      longDescription: 'Date when the submittal was made',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  description: {
    fieldTypeId: 'description',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Description',
      longDescription: 'Detailed description of the submittal',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  submittedBy: {
    fieldTypeId: 'submittedBy',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Submitted By',
      longDescription: 'Name or ID of the person who submitted the document',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: 'Submitted by is required',
  },
  reviewedBy: {
    fieldTypeId: 'reviewedBy',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Reviewed By',
      longDescription: 'Name or ID of the person who reviewed the submittal',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  reviewDate: {
    fieldTypeId: 'reviewDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Review Date',
      longDescription: 'Date when the submittal was reviewed',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  extendedSellPrice: {
    fieldTypeId: 'extendedSellPrice',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Extended Sell Price',
      longDescription: 'Total sell price for the line item (sell price * quantity)',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  lineItemsSummary: {
    fieldTypeId: 'lineItemsSummary',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'Line Items Summary',
      longDescription: 'Summary table of line items in the Quote',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: fieldDataType.table.validation,
    errorMessage: fieldDataType.table.errorMessage,
  },
  billOfMaterials: {
    fieldTypeId: 'billOfMaterials',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'BOM Table',
      longDescription: 'Bill of Materials (BOM) Table',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: (value: any) => Array.isArray(value),
  },
  lineItemsCommission: {
    fieldTypeId: 'lineItemsCommission',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'Line Items Commission',
      longDescription: 'Commission details table for line items in the Quote',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: fieldDataType.table.validation,
    errorMessage: fieldDataType.table.errorMessage,
  },
  totalSaleAmount: {
    fieldTypeId: 'totalSaleAmount',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Total Sale Amount',
      longDescription: 'Sum of all extended prices in the quote',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  paymentTerms: {
    fieldTypeId: 'paymentTerms',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Payment Terms',
      longDescription: 'Terms of payment for the quote',
    },
    status: RecordStatus.Active,
    defaultValue: 'Net 30 days',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  totalCommissionAmount: {
    fieldTypeId: 'totalCommissionAmount',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Total Commission Amount',
      longDescription: 'Sum of all commission amounts across all line items',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
};